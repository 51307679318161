<template>
    <section class="notification">
    <div class="page-header">
      <h3 class="page-title">
        Notifications
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Notifications</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <!-- liste des notifications -->
              <div class="col-12 results" :class="{ unread: !notification.isreadonboard}" v-for="notification in notifications" :key="notification.key"
             >
                <div class="pt-4 border-bottom">
                    <div class="d-flex justify-content-end">
                       <b-dropdown variant="link" no-caret toggle-class="text-decoration-none">
                        <template #button-content>
                          <i class="fa fa-ellipsis-h"></i>
                        </template>
                        <b-dropdown-item @click="unreadNotification(notification.ulid, notification.key)">Marquer comme non lue</b-dropdown-item>
                        <b-dropdown-item @click="deleteNotification(notification.ulid)">Supprimer</b-dropdown-item>
                      </b-dropdown>
                      
                    </div>
                    <div class="d-flex justify-content-between">
                        <h3 class="text-primary d-block h4 mb-0 pointer"  @click="ordersDetails(notification)">
                          {{
                             notification.object=="reservation"?"Nouvelle réservation"
                            :notification.object=="cancellation"?"Annulation réservation"
                            :"Vous avez une notification"
                          }}
                          </h3>
                        <p class="text-muted">{{formatDate2(notification.created_at)}}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>
                            <!-- <h3 class="text-primary">Jean Pierre</h3> -->
                            <!-- <p class="page-description mt-1 w-75 text-muted">{{notification.message}}</p> -->
                            <span v-html="notification.message"></span>
                        </div>
                        <!-- icone -->
                        <div class="preview-thumbnail">
                          <div v-if="notification.object=='reservation'" class="preview-icon bg-success d-flex justify-content-center align-items-center">
                              <i class="mdi mdi-calendar"></i>
                            </div>
                          <div v-else-if="notification.object=='cancellation'" class="preview-icon bg-danger d-flex justify-content-center align-items-center">
                            <i class="mdi mdi-calendar"></i>
                          </div>
                          <div v-else class="preview-icon bg-primary d-flex justify-content-center align-items-center">
                            <i class="fa fa-bell-o"></i>
                          </div>
                        </div>
                    </div>
                </div>
              </div>
             
              
              <nav class="col-12" aria-label="Page navigation">
                <ul class="pagination mt-5">
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">Previous</a>
                  </li>
                  <li class="page-item  active">
                    <a class="page-link" href="javascript:void(0);">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0);">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { dateMixin } from './mixins/date'
import { notificationsMixin } from './mixins/notifications'
// import axios from 'axios'
// import {mapState} from 'vuex';
export default {
    name: "notification",
    mixins:[dateMixin,notificationsMixin],
    data(){
        return{

        }
    },
    computed:{
      notifications(){
        let notifications = this.$store.state.notifications.notifications
        return notifications
      }
    },
    methods:{
      ordersDetails(notification){
        this.$router.push({
          name:"ordersDetails",
          params:{
            ulid:notification.object_id
          }
        })
        this.readNotification(notification.ulid)
      }
    },
    mounted(){
    }
}
</script>
<style scoped>
.preview-icon {
    color:#fff; 
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
}
.unread {
  background-color: #dff9f5
}
/* dropdown button */
::v-deep .b-dropdown .btn {
  padding: 0px;
}
::v-deep .b-dropdown:hover .btn {
  color: rgb(205, 205, 205);
}
</style>