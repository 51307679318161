import axios from "axios"

export let notificationsMixin = {
    name:"notificationsMixin",
    methods:{
        readNotification(ulid) {
            axios.put(`notification/${ulid}`, {
                isreadonboard : true
            })  
            .then(resRead=>{console.log(resRead);})
            .catch(errRead=>{console.log({errRead});})
        },
        unreadNotification(ulid) {
            axios.put(`notification/${ulid}`, {
                isreadonboard : false
            })  
            .then(resRead=>{console.log(resRead);})
            .catch(errRead=>{console.log({errRead});})
        },
        deleteNotification(ulid){
            this.$swal({
                title: 'Suppression',
                text: "Voulez-vous supprimer cette notification?",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, supprimer'
              }).then((result) => {
                if (result.value) {
                    axios.delete(`notification/${ulid}`)
                    .then(resDeleteNotification => {
                        console.log({resDeleteNotification});
                        alert('Notification supprimée')
                        window.location.reload()
                    })
                    .catch(errDelete => errDelete)
                  this.$swal(
                    'Supprimée!',
                    'La notification a été supprimée',
                    'success'
                  )
                }
              })
        }
    }
}